<template>
  <div class="course-list">
    <v-container>
      <div class="top-menu d-flex mb-2">
        <a class="link-text pt-1 pr-2" text href="/course-list-type" color="primary"
          >Course List
        </a>
        <i class="pt-2 icon fas fa-angle-right grey--text darken3"></i>
        <div class="pt-1 pl-2 grey--text darken3">Gap Selling Online Training Course</div>
      </div>
      <v-row>
        <v-col class="pa-8" cols="12" md="4" v-for="section in sections" v-bind:key="section._id">
          <!-- <p>{{ $t('vid_lang_name') }} lang- {{$i18n.locale}}</p> -->

          <div
            class="mx-auto section-content-wrapper"
            :class="section.locked ? 'locked' : 'unlocked'"
            @click="goToSection(section._id, section.locked)"
          >
            <div class="lesson-wrapper">
              <v-img
                class="lesson-image mx-auto mb-2"
                :src="section.previewURL"
                width="100%"
              ></v-img>
            </div>

            <div class="row ml-1">
              <div class="title" :class="section.locked ? 'locked' : 'unlocked'">
                {{ section.name }}
              </div>

              <div class="check ml-2 pt-1" v-if="section.completed">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="row ml-1">
              <v-progress-linear
                :rounded="true"
                :value="section.lessonProgressPercent"
                color="#C7070E"
                background-color="#ffffff"
                height="10"
                class="mt-2"
              ></v-progress-linear>
            </div>

            <div class="row mb-12 ml-1">
              <div class="font-weight-bold mt-2">
                {{ lessonCount(section) }} Lessons | Estimated Time {{ section.length }} min
              </div>

              <!-- <div class="check ml-3 mt-4" v-if="section.completed">
                <i class="fas fa-check"></i>
              </div>-->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showHelp" max-width="800px">
      <v-card class="pa-5" @click="showHelp = false">
        <div class="text-right">
          <v-spacer></v-spacer>

          <v-btn class="mr-2" icon @click="showHelp = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>

        <h2 class="text-center my-4">Complete the previous section to continue</h2>

        <div class="text-center">
          <v-img contain max-height="500px" class="mx-auto" src="/img/intro_how_to.png"> </v-img>
        </div>
      </v-card>
    </v-dialog>

    <!-- Vid Cen -->

    <!-- <VideoCensored :showDialog="showVideoCensoredDialog" :sectionId="activeSectionId"></VideoCensored> -->
  </div>
</template>

<style lang="scss" scoped>
.link-text {
  text-decoration: none;
  color: var(--v-primary-base);
}
.lesson-wrapper {
  position: relative;
}
// .check{
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   margin: 60px 120px;
//   z-index: 99;
//   font-size: 95px;
//   color:rgba($color: #C7070E, $alpha: .8);
// }
.check {
  color: var(--v-primary-base);
}
.course-list {
  background-color: #f2f2f2;
}

.font-weight-bold {
  color: var(--v-accent-lighten1);
  padding-top: 10px;
}

.section-content-wrapper {
  // width: 350px;
  &.unlocked {
    cursor: pointer;
  }
  &.locked {
    .lesson-image {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
}
.title {
  color: var(--v-accent-lighten3);
  font-weight: bold;
  &.unlocked {
    color: var(--v-primary-base);
  }
  &.section-active {
    color: var(--v-primary-base);
  }
}
</style>

<script>
import { mapState } from "vuex";
import GET_SECTION from "../graphql/GetSection.gql";
import GET_ACTIVE_CHECK from "../graphql/GetActiveCheck.gql";
import router from "../router";
import analytics from "../module/analytics";
// import VideoCensored from "@/components/VideoCensored";

export default {
  // components: { VideoCensored },
  data() {
    return {
      showHelp: false,
      sections: null,
      // showVideoCensoredDialog: false,
      activeSectionId: null,
      checkedPickUp: this.$route.query.checkpickup ? false : true,
    };
  },
  mounted() {
    if (
      this.auth &&
      this.auth.authUser &&
      !this.auth.authUser.isTrainingUser &&
      !this.auth.authUser.isTrainingUserGapPlus &&
      this.auth.authUser.account.paymentPlan &&
      this.auth.authUser.account.paymentPlan.isTeam
    ) {
      router.push("/settings");
    }

    this.getCourseList();
    analytics.page("mountCourseList", [{ name: "path", value: "course-list" }]);
  },
  computed: mapState(["auth"]),
  methods: {
    async getCourseList() {
      if (!this.auth || (this.auth && !this.auth.authUser)) {
        router.push("sign-in");
        return;
      }
      // if (
      //   ((!this.auth.authUser.isAdmin && !this.auth.authUser.account.payment) ||
      //     (this.auth.authUser.account.payment && !this.auth.authUser.account.payment.id)) &&
      //   this.auth.authUser.account.paymentPlanId != "demo" &&
      //   this.auth.authUser.account.selectedPlanId != "po_internal"
      // ) {
      //   confirm("There is an issue with your account. Please update your information.");
      //   router.push("/settings?tabName=plan&i=payment");
      //   return;
      // }
      const self = this;
      //
      // if (
      //   window.location.host.indexOf("app.gapsellingonlinetraining.com") > -1 &&
      //   this.auth.authUser.email.indexOf("myhive.io") == -1
      // ) {
      const getActiveCheckResult = await this.$apollo
        .query({
          query: GET_ACTIVE_CHECK,
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          alert("There is an issue with your account. Please update your information.");
          window.location.href = "/settings?tabName=plan";
          return;
        });

      if (!getActiveCheckResult.data.activeCheck.success) {
        alert("There is an issue with your account. Please update your information.");
        window.location.href = "/settings?tabName=plan";
      }
      // }
      //

      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            groupId: "default",
            type: "gap",
          },
        })
        .catch((error) => {
          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      // console.log("getSectionResult", getSectionResult.data.section);
      this.sections = getSectionResult.data.section;
      const currentSection = this.sections.find((s) => s.progress);
      if (currentSection && !this.checkedPickUp) {
        this.checkedPickUp = true;
        setTimeout(() => {
          const confirm = window.confirm("Do you want to pick up where you left off?");
          if (confirm) {
            self.goToSection(currentSection._id, false, true);
          }
        }, 1000);
        // progress
      }
    },
    lessonCount(section) {
      const lessons = section.lessons.filter((l) => !l.parentLessonId);
      return lessons.length;
    },
    goToSection(sectionId, locked, pickup) {
      if (sectionId && !locked) {
        this.activeSectionId = sectionId;
        // if (this.auth.videoCensored === undefined) {
        // this.showVideoCensoredDialog = true;videoCensoredDialog
        // } else {
        let route = {
          path: `section/${sectionId}`,
        };
        if (pickup) {
          route.query = { pickup: pickup ? "true" : null };
        }
        router.push(route);
        // }
      } else {
        this.showHelp = true;
      }
    },
    setVideoCensored(censored) {
      this.$store.dispatch("auth/updateVideoCensored", {
        videoCensored: censored,
      });
      this.showVideoCensoredDialog = false;
      this.goToSection(this.activeSectionId);
    },
  },
};
</script>
